import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import "../../../css/custom-css/custom-products-services.css"

import Layout from "../../../components/custom-components/Layout"
import BannerHeader from "../../../components/custom-components/BannerHeader"

// import bg from "../../../images/products-and-services/header-cmo.webp"
import bg from "../../../images/products-and-services/header-therapeutics.jpg"
// import bg from '../../../images/products-and-services/tiles-10.jpg'
import Seo from "../../../components/seo"

export const query = graphql`
  {
    image1: contentfulServices(title: { eq: "therapeutics-1" }) {
      image {
        gatsbyImageData(layout: FIXED, height: 200, width: 300)
      }
    }
    image2: contentfulServices(title: { eq: "therapeutics-2" }) {
      image {
        gatsbyImageData(layout: FIXED, height: 200, width: 300)
      }
    }
    image3: contentfulServices(title: { eq: "therapeutics-3" }) {
      image {
        gatsbyImageData(layout: FIXED, height: 200, width: 300)
      }
    }
  }
`

const Therapeutics = () => {
  const data = useStaticQuery(query)
  const image1 = data.image1
  const image2 = data.image2
  const image3 = data.image3
  return (
    <Layout>
      <Seo title="Therapeutics" />

      <BannerHeader background={bg} title="Therapeutics" servicesDetails />
      <div className="content-block">
        <div className="section-full content-inner-custom">
          <div className="container">
            <div>
              <Link href="/products-and-services">
                <div className="icon-content">
                  <h5 className="dlab-title">
                    <span className="icon-sm">
                      <i className="ti-arrow-left"></i>
                    </span>
                    Back
                  </h5>
                </div>
              </Link>
            </div>

            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-5">
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li>
                      <Link to="/products-and-services/services/novel-diagnostics">
                        Novel Diagnostics
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/products-and-services/services/therapeutics">
                        Therapeutics
                      </Link>
                    </li>
                    <li>
                      <Link to="/products-and-services/services/other-services">
                        Other Services
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8 col-md-7 m-b30">
                {/* <div className="row">
                  <div className="col-lg-6 col-md-12 m-b30">
                    <div className="dlab-box">
                      <div className="dlab-media">
                        <GatsbyImage image={image1.image.gatsbyImageData} />
                      </div>
                      <div className="dlab-info m-t30 ">
                        <h4 className="dlab-title m-t0">
                          <Link to="#">Therapeutics Subheading 1 </Link>
                        </h4>
                        <p className="text-justify">
                          We have various drugs in key therapeutics areas:
                          biosimilars, cancer drugs, vaccines and diagnostic
                          kits.
                        </p>
                        <p className="text-justify">
                          Innogene Kalbiotech provides targeted therapy in the
                          treatment of cancer using an innovative monoclonal
                          antibody. Our humanized monoclonal antibody has been
                          approved in more than 20 countries for various
                          indications such as adult and paediatric glioma, head
                          and neck cancer, and NPC (nasopharyngeal cancer).
                        </p>
                        <p className="text-justify">
                          The antibody has been commercialized in most of the
                          ASEAN countries, and Nigeria. It has also been granted
                          orphan drug status by EMEA (European Medicines Agency)
                          for pancreatic cancer treatment.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dlab-box">
                      <div className="dlab-media m-b30 p-b5">
                        <GatsbyImage image={image2.image.gatsbyImageData} />
                      </div>
                      <div className="dlab-media">
                        <GatsbyImage image={image3.image.gatsbyImageData} />
                      </div>
                      <div className="dlab-info m-t30">
                        <h4 className="dlab-title m-t0">
                          <Link to="#">Therapeutics Subheading 2</Link>
                        </h4>
                        <p className="text-justify">
                          Nimotuzumab is approved in more than 30 countries and
                          Orphan drug designated by US FDA, EMEA and Swissmedic
                          for Glioma and by EMEA for pancreatic cancer
                          treatment.
                        </p>
                        <p className="text-justify">
                          We also offer several biosimilar products, which are
                          distributed in most ASEAN countries, Georgia, Nigeria
                          and Sri Lanka. Innogene Kalbiotech has demonstrated a
                          consistent ability to source the most cost-effective
                          ethical pharmaceuticals and biosimilars from across
                          the globe to serve the unmet needs and growing demand
                          of Asia.
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-md-4 col-sm-12 mb-4">
                    <GatsbyImage image={image1.image.gatsbyImageData} />
                  </div>
                  <div className="col-md-4 col-sm-12 mb-4">
                    <GatsbyImage image={image2.image.gatsbyImageData} />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <GatsbyImage image={image3.image.gatsbyImageData} />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h4 className="dlab-title mt-2">Therapeutics</h4>
                    <p className="text-justify">
                      We have various drugs in key therapeutics areas such as biosimilars, novel cancer drugs and diagnostics.
                    </p>
                    <p className="text-justify mt-3">
                      Innogene Kalbiotech provides targeted therapy in the treatment of cancer using an innovative monoclonal antibody. Our humanized monoclonal antibody has been approved in more than 30 countries for various indications such as adult and paediatric giloma, head and neck cancer, nasopharyngeal cancer, and pancreatic cancer.
                    </p>
                    <p className="text-justify mt-3">
                      The antibody has been commercialized in most of the ASEAN countries. It has also been granted orphan drug status by US FDA and EMEA for Glioma and pancreatic cancer treatment.
                    </p>
                    <p className="text-justify mt-3">
                      We also offer several biosimilar products, which are commercialized in most ASEAN countries, Kalbiotech has demonstrated a consistent ability to source the cost-effective biopharmaceuticals from across the globe to serve the unmet needs and growing demand of Asia.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Therapeutics
